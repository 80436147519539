<template>
  <div class="all_main_wrap">
    <!-- 机构 -->
    <div class="all_content_box" v-if="detailData">
      <div class="Up_Down_inner">
        <span class="all_left_name">机构信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>机构邮箱：<span>{{detailData.email}}</span></p>
            <p>机构码：<span>{{detailData.orgCode}}</span></p>
            <p>机构名称：<span>{{detailData.orgName}}</span></p>
          </div>
          <div class="line_item">
            <p>企业类型：<span>{{detailData.orgType == 1?'企业':'非企业'}}</span></p>
            <p>地区：<span>{{detailData.orgProvice}}{{detailData.orgCity}}{{detailData.orgArea?detailData.orgArea:''}}{{detailData.orgAddress}}</span></p>
            <p>是否为定点机构：<span>{{detailData.appointedFlag?'是':'否'}}</span></p>
          </div>
          <div class="line_item">
            <p>企业规模：<span>{{detailData.dictionaryValue}}</span></p>
            <p>所在行业：<span>{{detailData.industry}} - {{detailData.industrySub}}</span></p>
            <p v-if="detailData.orgType == 1">统一社会信用代码：<span>{{detailData.institutionCode}}</span></p>
          </div>
          <div class="line_item">
            <p>{{detailData.orgType == 1?'营业执照/':''}}事业单位法人证书：
              <viewer v-if="detailData.businessPhoto">
                <img :src="detailData.businessPhoto" alt="">
              </viewer>
            </p>
            <p>一般纳税人资格证明：
              <viewer v-if="detailData.taxpayerPhoto">
                <img :src="detailData.taxpayerPhoto" alt="">
              </viewer>
            </p>
          </div>
        </div>
      </div>

      <div class="Up_Down_inner">
        <span class="all_left_name">机构联系人</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>姓名：<span>{{detailData.contactName}}</span></p>
            <p>证件信息：<span>{{detailData.contactIdcard}}</span></p>
            <p>手机号：<span>{{detailData.contactTell}}</span></p>
          </div>
          <div class="line_item">
            <p>机构职位：<span>{{detailData.contactPost}}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: ['id'],
  // 数据对象
  data () {
    return {
      detailData: null
    }
  },
  // 事件处理器
  methods: {
    getDetail() {
      this.$ajax({
        url: '/hxclass-management/organization/info/certification/details',
        params: {
          userId: this.id
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.detailData = res.data;
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 获取机构详情
    this.getDetail();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.Up_Down_inner{
  margin-bottom: 10px;
}
.all_main_wrap{
  padding: 0 16px;
}
.table-box{
  width: 800px;
}
.bgDiv{
  .line_item{
    display: flex;
    margin-bottom: 24px;
    p{
      width: calc(100% / 3);
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      span{
        font-weight: 500;
        color: #333333;
      }
      img{
        margin-top: 10px;
        display: block;
        width: 230px;
        cursor: pointer;
      }
    }
  }
  .line_item:last-child{
    margin-bottom: 0;
  }
}
</style>
