<template>
  <div class="all_main_wrap">
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <div>
          <a-table
            class="table-template"
            :rowKey="(item,index)=>index"
            :columns="StaffColumns"
            :data-source="StaffList"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="userName" slot-scope="item">
              {{item?'用户名：'+item:'暂无账号'}}
            </template>
            <template slot="departmentName" slot-scope="item">
              {{item?item:'-'}}
            </template>
            <template slot="bindStatus" slot-scope="item">
              {{item?'在职':'离职'}}
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const StaffColumns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    scopedSlots: { customRender: 'index' }
  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "name"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "mobile"
  },
  {
    title: "证件号码",
    align:'center',
    dataIndex: "idCard",
  },
  {
    title: "部门",
    align:'center',
    dataIndex: "departmentName",
    scopedSlots: { customRender: 'departmentName' }
  },
  {
    title: "云课堂个人账号",
    align:'center',
    dataIndex: "userName",
    scopedSlots: { customRender: 'userName' }
  },
  {
    title: "在职状态",
    align:'center',
    dataIndex: "bindStatus",
    scopedSlots: { customRender: 'bindStatus' }
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: ['id'],
  // 数据对象
  data () {
    return {
      StaffColumns,
      StaffList:[]
    }
  },
  // 事件处理器
  methods: {
    getStaffList() {
      this.$ajax({
        url: '/hxclass-management/organization/course/manage/person/list',
        params: {
          userId: this.id
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.StaffList = res.data;
        }
      })
    }
    // 
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getStaffList();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.all_main_wrap{
  padding: 0 16px;
}
</style>
