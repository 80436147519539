<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">用户查询</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <router-link class="crumbs_item" tag="a" to="/admin/Mechanism/userSearch">用户查询 / </router-link>
          <span class="crumbs_item crumbs_last">详情</span>
        </div>
      </div>
    </div>
    <!-- 详情 -->
    <div class="detail-box">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="基本信息">
          <EssentialInfo-List :id="id" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="员工信息">
          <StaffInfo-List :id="id" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="支付记录">
          <PayRecord-List :id="id" />
        </a-tab-pane>
        <a-tab-pane key="4" tab="学习情况">
          <StudyInfo :id="id" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import EssentialInfoList from './userDetail/EssentialInfo.vue'  // 基本信息
import StaffInfoList from './userDetail/StaffInfo.vue'  // 员工信息
import PayRecordList from './userDetail/PayRecord.vue'  // 支付记录
import StudyInfo from './userDetail/studyInfo.vue' // 学习情况
export default {
  // 可用组件的哈希表
  components: { EssentialInfoList, StaffInfoList, PayRecordList, StudyInfo },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      id: null
    }
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () {
    this.id = this.$route.query.userId;
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.detail-box{
  /deep/.ant-tabs-bar{
    border: none;
  }
  /deep/ .ant-tabs-tab{
    font-size: 18px;
  }
}
</style>
