<template>
  <div class="all_main_wrap">
    <div class="all_content_box">
      <p class="title">考试数据</p>
      <div class="findItem">
        <a-input v-model="examName" placeholder="请输入考试名称" />
        <a-date-picker @change="onChange" />
        <a-button type="primary" @click="getStudyList()">搜索</a-button>
      </div>
      <div class="Up_Down_inner">
        <div>
          <a-table
            class="table-template"
            :rowKey="(item, index) => index"
            :columns="studyColumns"
            :data-source="studyList"
            :bordered="true"
            :pagination="false"
          >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="userName" slot-scope="item">
              {{ item ? "用户名：" + item : "暂无账号" }}
            </template>
            <template slot="bindStatus" slot-scope="item">
              {{ item ? "在职" : "离职" }}
            </template>
          </a-table>
        </div>
      </div>
      <p class="title" style="margin-top: 20px">证书数据</p>
      <div class="findItem">
        <a-input v-model="cerdata.username" placeholder="请输入用户名称" />
        <a-input v-model="cerdata.certifName" placeholder="请输入证书名称" />

        <a-select style="width: 220px" placeholder="请选择证书类型" v-model="cerdata.type">
          <a-select-option value="2"> 课程证书 </a-select-option>
          <a-select-option value="1"> 考试证书 </a-select-option>
        </a-select>
        <a-button type="primary" @click="getCertificateList()"
          >搜索证书</a-button
        >
      </div>
      <div class="Up_Down_inner">
        <div>
          <a-table
            class="table-template"
            :rowKey="(item, index) => index"
            :columns="certificateColumns"
            :data-source="certificateList"
            :bordered="true"
            :pagination="false"
          >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="userName" slot-scope="item">
              {{ item ? "用户名：" + item : "暂无账号" }}
            </template>
            <template slot="bindStatus" slot-scope="item">
              {{ item ? "在职" : "离职" }}
            </template>
            <template slot="operation" slot-scope="text, record">
              <div class="btn_router_link">
                <a @click="priviewPic(record.picture)">查看</a>
                <span>|</span>
                <a @click="downLoad(record.picture)">下载</a>
              </div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <!-- 查看证书图片 -->
    <a-modal v-model="visible" title="证书图片">
      <div class="viscimg">
        <img :src="viscimg" alt="" />
      </div>
      <template slot="footer">
        <a-button @click="visible = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const studyColumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "考试名称",
    align: "center",
    dataIndex: "examname",
  },
  {
    title: "报名方式",
    align: "center",
    dataIndex: "signupType",
  },
  {
    title: "补考次数",
    align: "center",
    dataIndex: "markNum",
  },
  {
    title: "考试日期",
    align: "center",
    dataIndex: "examdate",
  },
  {
    title: "考试场次",
    align: "center",
    dataIndex: "examdatetime",
  },
  {
    title: "报名状态",
    align: "center",
    dataIndex: "auditstatus",
  },
  {
    title: "考试状态",
    align: "center",
    dataIndex: "examstatus",
  },
  {
    title: "最终成绩",
    align: "center",
    dataIndex: "totalscore",
  },
];

const certificateColumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "证书名称",
    align: "center",
    dataIndex: "tempName",
  },
  {
    title: "证书编号",
    align: "center",
    dataIndex: "code",
  },
  {
    title: "获证时间",
    align: "center",
    dataIndex: "getcertifTime",
  },
  {
    title: "证书类型",
    align: "center",
    dataIndex: "type",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: ["id"],
  // 数据对象
  data() {
    return {
      visible: false,
      viscimg: "",
      studyColumns,
      certificateColumns,
      studyList: [],
      certificateList: [],
      examName: "", // 考试名称
      examDate: "", // 考试时间

      // 证书数据
      cerdata: {
        certifName: "",
        type: undefined,
        username: "",
        orgId: this.id,
      },
    };
  },
  // 事件处理器
  methods: {
    // 证书预览
    priviewPic(e) {
      this.viscimg = e;
      this.visible = true;
    },
    // 证书下载
    downLoad(url, name) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          download(url, "图片");
          // 用完释放URL对象
          URL.revokeObjectURL(url);
        });
      };
      function download(href, name) {
        let eleLink = document.createElement("a");
        eleLink.download = name;
        eleLink.href = href;
        eleLink.click();
        eleLink.remove();
      }
    },
    onChange(e, dataStr) {
      this.examDate = dataStr;
    },
    getStudyList() {
      this.$ajax({
        url: "/hxclass-management/exam/signup/query/signup-list",
        params: {
          orgId: this.id,
          examDate: this.examDate,
          examName: this.examName,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.studyList = res.data;
        }
      });
    },

    getCertificateList() {
      this.$ajax({
        url: "/hxclass-management/exam/signup/query/certif-list",
        params: this.cerdata,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.certificateList = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getStudyList();
    this.getCertificateList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.all_main_wrap {
  padding: 0 16px;
}
.findItem {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  // /deep/.ant-form-item-control-wrapper{
  //   width: 240px !important;
  //   height: 29px !important;
  // }
  /deep/.ant-input {
    width: 200px !important;
    height: 29px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }
  .ant-calendar-picker {
    /deep/.ant-input {
      width: 220px !important;
      margin-right: 0;
    }
  }

  .ant-btn {
    margin-left: 24px;
  }
}
.btnBorder {
  border-color: #3681ef;
  color: #3681ef;
}
.viscimg {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
