<template>
  <div class="all_main_wrap">
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <div>
          <a-table
            class="table-template"
            :rowKey="(item, index) => index"
            :columns="columns"
            :data-source="listData"
            :bordered="true"
            :pagination="false"
          >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="payPrice" slot-scope="item">
              ￥{{ item.toFixed(2) }}
            </template>
            <template slot="productType" slot-scope="item">
              {{ item == 1 ? "普通商品" : "学习包" }}
            </template>
            <template slot="operation" slot-scope="item, row">
              <a @click="getRecordList(row)">发放记录</a>
              <a @click="getHistoryList(row)" style="margin-left: 10px"
                >发放历史</a
              >
            </template>
          </a-table>
        </div>
      </div>
      <div>收货地址</div>
      <div>
        <!-- 收货地址 -->
        <div class="Up_Down_inner">
          <a-table
            class="table-template"
            :rowKey="(item) => item.id"
            :columns="addressColumns"
            :data-source="addressList"
            :bordered="true"
            :pagination="false"
          >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center">
                {{ i + 1 }}
              </div>
            </template>
            <template slot="isDefault" slot-scope="item, row">
              <div style="text-align: center">
                {{ row.isDefault ? "是" : "否" }}
              </div>
            </template>

            <!-- 收货地址拼接 -->
            <template slot="address" slot-scope="item">
              <span v-if="item.proviceName">{{ item.proviceName }}-</span>
              <span v-if="item.cityName">{{ item.cityName }}-</span>
              <span v-if="item.areaName">{{ item.areaName }}</span>
              <span v-if="item.address">-{{ item.address }}</span>
            </template>
          </a-table>
        </div>
      </div>
      <div>发票抬头</div>
      <div>
        <!-- 发票抬头 -->
        <div class="Up_Down_inner">
          <a-table
            class="table-template"
            :rowKey="(item) => item.userInvoiceId"
            :columns="invoiceColumns"
            :data-source="invoiceList"
            :bordered="true"
            :pagination="false"
          >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center">
                {{ i + 1 }}
              </div>
            </template>

            <!-- 收货地址拼接 -->
            <template slot="headerType" slot-scope="item">
              <span>{{ item == 1 ? "企业" : "个人" }}</span>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <!-- 发放历史 -->
    <a-modal
      :footer="null"
      align="center"
      v-model="historyVisible"
      title="发放历史"
      width="700px"
    >
      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="historyColumns"
        :data-source="historyList"
        :pagination="false"
      >
      </a-table>
    </a-modal>
    <!-- 发放记录 -->
    <a-modal
      :footer="null"
      align="center"
      v-model="visible"
      title="发放记录"
      width="1200px"
    >
      <p class="p1">
        <span v-for="(item, index) in productNameList" :key="index"
          >{{ item }}{{ index == productNameList.length - 1 ? "" : "、" }}</span
        >
      </p>
      <a-table
        class="table-template"
        :rowKey="(item, index) => index"
        :columns="columnsCopy"
        :data-source="recordList"
        :pagination="false"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ i + 1 }}
          </div>
        </template>
        <!-- 报名方式 -->
        <template slot="signupType" slot-scope="text">
          {{ text == 1 ? "统一报名" : "个人报名" }}
        </template>

        <!-- 学习进度 -->
        <template slot="progress" slot-scope="text">
          {{ text * 100 }}%
        </template>

        <!-- 课程期数 -->
        <template slot="class" slot-scope="item">
          {{ item.className }} {{ item.startTime }} ~ {{ item.endTime }}
        </template>
        <template slot="operation" slot-scope="item, row">
          <div class="operation">
            <p class="operationBtn" @click="setStudyDetail(row)">学习详情</p>
          </div>
        </template>
      </a-table>
    </a-modal>
    <!-- 学习包详情 -->
    <a-modal
      :footer="null"
      align="center"
      v-model="detailVisible"
      title="学习包详情"
      width="700px"
    >
      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns3"
        :data-source="detailData"
        :pagination="false"
      >
        <!-- 课程期数 -->
        <template slot="operation" slot-scope="item">
          <template v-if="item.startTime">
            {{ item.className }} {{ item.startTime }} ~ {{ item.endTime }}
          </template>
          <template v-else> - </template>
        </template>
        <!-- 学习进度 -->
        <template slot="progress" slot-scope="text">
          {{ text * 100 }}%
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "数量",
    align: "center",
    dataIndex: "productCount",
  },
  {
    title: "订单编号",
    align: "center",
    dataIndex: "subCode",
  },
  {
    title: "支付时间",
    align: "center",
    dataIndex: "paymentTime",
  },
  {
    title: "实付金额",
    align: "center",
    dataIndex: "payPrice",
    scopedSlots: { customRender: "payPrice" },
  },
  {
    title: "活动来源",
    align: "center",
    dataIndex: "productType",
    scopedSlots: { customRender: "productType" },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "index6",
    scopedSlots: { customRender: "operation" },
  },
];

const recerdColumns = [
  // 记录列表
  {
    title: "序号",
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "发放时间",
    dataIndex: "grantTime",
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "userName",
    align: "center",
  },
  {
    title: "手机号",
    dataIndex: "mobile",
    align: "center",
  },
  {
    title: "所在部门",
    dataIndex: "departmentName",
    align: "center",
  },
  {
    title: "考试报名方式",
    align: "center",
    dataIndex: "signupType",
    scopedSlots: { customRender: "signupType" },
  },
];

const columns3 = [
  // 学习包详情
  {
    title: "课程名称",
    dataIndex: "courseName",
    align: "center",
  },
  {
    title: "班级期数",
    scopedSlots: { customRender: "operation" },
    align: "center",
  },
  {
    title: "学习进度",
    dataIndex: "studyProgress",
    scopedSlots: { customRender: "progress" },
    align: "center",
  },
];

const historyColumns = [
  {
    title: "学员姓名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "操作详情",
    align: "center",
    dataIndex: "operationDetail",
  },
  {
    title: "操作时间",
    align: "center",
    dataIndex: "createTime",
  },
];

const addressColumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "consignee",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "phone",
  },
  {
    title: "地址",
    align: "center",
    scopedSlots: { customRender: "address" },
  },
  {
    title: "是否为收货地址",
    align: "center",
    scopedSlots: { customRender: "isDefault" },
  },
];

const invoiceColumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "抬头类型",
    align: "center",
    dataIndex: "headerType",
    scopedSlots: { customRender: "headerType" },
  },
  {
    title: "发票抬头",
    align: "center",
    dataIndex: "invoiceHeader",
  },
  {
    title: "电子邮箱",
    align: "center",
    dataIndex: "email",
  },
  {
    title: "税号",
    align: "center",
    dataIndex: "dutyParagraph",
  },
  {
    title: "开户银行",
    align: "center",
    dataIndex: "bank",
  },
  {
    title: "银行账号",
    align: "center",
    dataIndex: "bankAccount",
  },
  {
    title: "企业地址",
    align: "center",
    dataIndex: "companyAddress",
  },
  {
    title: "企业电话",
    align: "center",
    dataIndex: "companyPhone",
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: ["id"],
  // 数据对象
  data() {
    return {
      columns,
      columns3,
      recerdColumns,
      historyColumns,
      addressColumns, // 收货地址
      invoiceColumns, // 发票抬头
      columnsCopy: [],
      listData: [],
      historyList: [],
      recordList: [],
      detailData: [],
      productNameList: null,
      visible: false,
      historyVisible: false, // 发放历史弹框
      detailVisible: false, // 学习包详情弹框
      addressList: [], // 收货地址
      invoiceList: [], // 发票抬头
    };
  },
  // 事件处理器
  methods: {
    // 获取支付记录列表
    getListData() {
      this.$ajax({
        url: "/hxclass-management/organization/course/manage/order/record",
        params: {
          userId: this.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.listData = res.data;
        }
      });
    },

    // 查询学习包详情
    setStudyDetail(e) {
      this.$ajax({
        url: "/hxclass-management/organization/course/course/openTime/progress",
        params: {
          openTimeId: e.openTimeId,
          orderCode: e.orderCode,
          userId: e.userId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.detailData = res.data;
          this.detailVisible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 获取发放记录列表
    getRecordList(row) {
      this.$ajax({
        url: "/hxclass-management/organization/course/manage/grant/record",
        params: {
          orderCode: row.subCode,
          productType: row.productType,
          userId: this.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.courseGrantRecordList.length) {
            this.recordList = res.data.courseGrantRecordList;
            this.productNameList = res.data.productNameList;

            this.columnsCopy = JSON.parse(JSON.stringify(this.recerdColumns));
            //  productType  商品状态 1.普通商品  2.学习包
            if (row.productType == 1) {
              let columns = [
                {
                  title: "学习进度",
                  dataIndex: "studyProgress",
                  scopedSlots: { customRender: "progress" },
                  align: "center",
                },
              ];
              if (this.recordList.length && this.recordList[0].startTime) {
                columns.push({
                  title: "课程期数",
                  align: "center",
                  scopedSlots: { customRender: "class" },
                });
              }
              this.columnsCopy = this.columnsCopy.concat(columns);
            } else if (row.productType == 2) {
              let columns = [
                {
                  title: "操作",
                  align: "center",
                  scopedSlots: { customRender: "operation" },
                },
              ];
              this.columnsCopy = this.columnsCopy.concat(columns);
            }
            this.visible = true;
          } else {
            this.$message.error("该商品暂无发放记录！");
          }
        }
      });
    },

    // 获取发放历史列表
    getHistoryList(row) {
      this.$ajax({
        url: "/hxclass-management/organization/course/manage/course/history",
        params: {
          orderCode: row.subCode,
          orgId: this.id,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data.length) {
            this.historyList = res.data;
            this.historyVisible = true;
          } else {
            this.$message.error("该商品暂无发放记录！");
          }
        }
      });
    },
    // 查询收货地址
    getAddressList(e) {
      this.$ajax({
        url: "/hxclass-management/ship/address/getUseList",
        params: {
          userId: e,
          // pageNum:1,
          // pageSize:100,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.addressList = res.data;
        }
      });
    },
    // 查询发票抬头
    getInvoiceList(e) {
      this.$ajax({
        url: "/hxclass-management/invoice/header/useList",
        params: {
          userId: e,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceList = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getListData();
    this.getAddressList(this.id); //收货地址
    this.getInvoiceList(this.id); //发票抬头
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.all_main_wrap {
  padding: 0 16px;
}
.p1 {
  text-align: left;
  margin-left: 7px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}
.operation {
  display: flex;
  justify-content: center;
  .operationBtn {
    font-size: 14px;
    font-weight: 400;
    color: #15b7dd;
    cursor: pointer;
  }
  .operationBtnLine {
    font-size: 14px;
    font-weight: 400;
    color: #15b7dd;
    margin: 0px 5px;
    cursor: pointer;
  }
}
.all_content_box {
  > div {
    margin-top: 10px;
  }
}
</style>
